const middleware = {}

middleware['contentful-galleries'] = require('../src/middleware/contentful-galleries.js')
middleware['contentful-galleries'] = middleware['contentful-galleries'].default || middleware['contentful-galleries']

middleware['l10n'] = require('../src/middleware/l10n.js')
middleware['l10n'] = middleware['l10n'].default || middleware['l10n']

middleware['legacy/index'] = require('../src/middleware/legacy/index.js')
middleware['legacy/index'] = middleware['legacy/index'].default || middleware['legacy/index']

middleware['legacy/rules/entity'] = require('../src/middleware/legacy/rules/entity.js')
middleware['legacy/rules/entity'] = middleware['legacy/rules/entity'].default || middleware['legacy/rules/entity']

middleware['legacy/rules/exhibitions'] = require('../src/middleware/legacy/rules/exhibitions.js')
middleware['legacy/rules/exhibitions'] = middleware['legacy/rules/exhibitions'].default || middleware['legacy/rules/exhibitions']

middleware['legacy/rules/explore'] = require('../src/middleware/legacy/rules/explore.js')
middleware['legacy/rules/explore'] = middleware['legacy/rules/explore'].default || middleware['legacy/rules/explore']

middleware['legacy/rules/galleries'] = require('../src/middleware/legacy/rules/galleries.js')
middleware['legacy/rules/galleries'] = middleware['legacy/rules/galleries'].default || middleware['legacy/rules/galleries']

middleware['legacy/rules/html'] = require('../src/middleware/legacy/rules/html.js')
middleware['legacy/rules/html'] = middleware['legacy/rules/html'].default || middleware['legacy/rules/html']

middleware['legacy/rules/portal'] = require('../src/middleware/legacy/rules/portal.js')
middleware['legacy/rules/portal'] = middleware['legacy/rules/portal'].default || middleware['legacy/rules/portal']

middleware['legacy/rules/record'] = require('../src/middleware/legacy/rules/record.js')
middleware['legacy/rules/record'] = middleware['legacy/rules/record'].default || middleware['legacy/rules/record']

middleware['legacy/rules/search'] = require('../src/middleware/legacy/rules/search.js')
middleware['legacy/rules/search'] = middleware['legacy/rules/search'].default || middleware['legacy/rules/search']

middleware['legacy/rules/static-pages'] = require('../src/middleware/legacy/rules/static-pages.js')
middleware['legacy/rules/static-pages'] = middleware['legacy/rules/static-pages'].default || middleware['legacy/rules/static-pages']

middleware['redirects'] = require('../src/middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

middleware['set-galleries'] = require('../src/middleware/set-galleries.js')
middleware['set-galleries'] = middleware['set-galleries'].default || middleware['set-galleries']

middleware['trailing-slash'] = require('../src/middleware/trailing-slash.js')
middleware['trailing-slash'] = middleware['trailing-slash'].default || middleware['trailing-slash']

export default middleware
